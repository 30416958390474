import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Clients = () => {
  return (
    <Layout>
      <SEO title="Clients" />
      <div className="row">
        <div className="col-12">
          <div className="coming-soon"> COMING SOON</div>
        </div>
      </div>
    </Layout>
  )
}

export default Clients
